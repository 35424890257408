import { useState } from 'react';
import { createModel } from 'hox';
import { Appointment } from '@/common/structures';
import { Appointments } from '@/services/appointments';

export default createModel(() => {
  const [loading, setLoading] = useState<boolean>(false);
  const fetch = async (
    user: string,
    month: number,
    year: number,
  ): Promise<Appointment[]> => {
    try {
      setLoading(true);
      return await Appointments.fetchFromUser(
        user,
      ).insideYearMonth(year, month);

    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    fetch,
    loading,
  };
});
