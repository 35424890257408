import styles from './index.less';
import {
  Row,
  Col,
  Layout,
  Select,
  Typography,
  Radio,
  Badge,
  Form,
  Modal,
  Button,
  Switch,
  Input,
  Spin,
  notification,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import Calendar from '@/molecules/DefaultCalendar';

import {
  CaretLeftFilled,
  CaretRightFilled,
  EyeOutlined,
  EyeInvisibleOutlined,
  HeartOutlined,
  UserOutlined,
  DoubleLeftOutlined,
  SaveOutlined,
  PlusSquareOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import { TimePicker } from '@/molecules';
import useCreateAppointment from '@/models/useCreateAppointment';
import useModifyAppointment from '@/models/useModifyAppointment';
import useRemoveAppointment from '@/models/useRemoveAppointment';
import usePage from '@/pages/model';
import { DatePicker } from '@/molecules/';
import { useLocation } from 'umi';
import { useEffect } from 'react';
import format from 'dayjs';

const { Content } = Layout;
const { Link } = Typography;

export default function IndexPage() {
  const page = usePage();
  const createAppointment = useCreateAppointment();
  const modifyAppointment = useModifyAppointment();
  const removeAppointment = useRemoveAppointment();
  const location = useLocation();

  const {
    load,
    loading,
    currentYear,
    setCurrentYear,
    currentMonth,
    setCurrentMonth,
    currentDate,
    setCurrentDate,
    currentUser,
    setCurrentUser,
    selectedDate,
    setSelectedDate,
    selectDate,
    selectedAppointment,
    setSelectedAppointment,
    appointmentsForSelectedDate,
    setAppointmentsForSelectedDate,
    mode,
    setMode,
    addingAppointment,
    setAddingAppointment,
    appointments,
  } = page;

  setCurrentUser(location.pathname.slice(1));

  const onChange = (value: Dayjs) => {
    setCurrentDate(value);
  };
  const onSelect = (value: Dayjs) => {
    selectDate(value);
  };

  const onFinish = async (values: {
    time: Dayjs;
    newDate: Dayjs;
    client: string;
  }) => {
    const { time, newDate, client } = values;

    const hour = time?.hour();
    const minute = time?.minute();

    try {
      if (selectedAppointment) {
        const date = newDate.date();
        const month = newDate.month();
        const year = newDate.year();
        const unixtime = dayjs()
          .set('year', year)
          .set('month', month)
          .set('date', date)
          .set('hour', hour)
          .set('minute', minute)
          .set('second', 0)
          .set('millisecond', 0)
          .valueOf();
        await modifyAppointment.modify(selectedAppointment.id!, {
          id: selectedAppointment!.id,
          user: currentUser,
          client,
          year,
          month,
          date,
          hour,
          minute,
          unixtime,
        });
        await load();
        setSelectedAppointment(undefined);
      } else {
        const date = selectedDate!.date();
        const month = selectedDate!.month();
        const year = selectedDate!.year();
        const unixtime = dayjs()
          .set('year', year)
          .set('month', month)
          .set('date', date)
          .set('hour', hour)
          .set('minute', minute)
          .set('second', 0)
          .set('millisecond', 0)
          .valueOf();
        await createAppointment.create({
          user: currentUser,
          year,
          month,
          date,
          hour,
          minute,
          client,
          unixtime,
        });
        await load();
        setAddingAppointment(false);
      }
    } catch (error: any) {
      console.error(error);
      notification.error({
        message: 'Error!',
      });
    }
  };
  return (
    <Content style={{ padding: 8 }}>
      <Modal
        width={360}
        visible={!!selectedDate}
        onCancel={() => {
          setSelectedDate(undefined);
          setAddingAppointment(false);
          setSelectedAppointment(undefined);
        }}
        footer={false}
        title={`${selectedDate?.format('ddd DD [de] MMMM YYYY')}`}
      >
        <Spin
          spinning={
            loading ||
            createAppointment.loading ||
            modifyAppointment.loading ||
            removeAppointment.loading
          }
        >
          {!addingAppointment && !selectedAppointment && (
            <Content style={{ paddingBottom: 8 }}>
              {appointmentsForSelectedDate
                .sort((a, b) => a.unixtime - b.unixtime)
                .map((appointment) => {
                  return (
                    <li
                      key={`${appointment.id}`}
                      style={{ padding: 4 }}
                      onClick={() => console.log(appointment)}
                    >
                      <Row justify="space-between">
                        <Col>
                          <Typography.Text
                            ellipsis
                            style={{ fontSize: '150%' }}
                          >
                            <Badge
                              color={'#ff85c0'}
                              style={{ paddingRight: 16 }}
                            />
                            <span
                              style={{
                                fontFamily: 'Noto Sans Mono',
                                fontWeight: 900,
                                fontSize: '75%',
                                paddingRight: 16,
                              }}
                            >
                              {dayjs()
                                .hour(appointment.hour)
                                .minute(appointment.minute)
                                .format('HH:mm')}
                            </span>{' '}
                            {appointment.client}
                          </Typography.Text>
                        </Col>
                        <Col>
                          <Button
                            onClick={() => setSelectedAppointment(appointment)}
                            icon={<EditOutlined />}
                            type="link"
                          />
                        </Col>
                      </Row>
                    </li>
                  );
                })}
              {appointmentsForSelectedDate.length === 0 && (
                <li>
                  <Typography.Text ellipsis style={{ fontSize: '150%' }}>
                    <span style={{ fontStyle: 'italic', fontWeight: 700 }}>
                      ¡Tienes el dia libre!{' '}
                    </span>
                  </Typography.Text>
                </li>
              )}
            </Content>
          )}

          {addingAppointment || selectedAppointment ? (
            <Form
              onFinish={onFinish}
              layout="vertical"
              size="middle"
              initialValues={{
                time: selectedAppointment
                  ? dayjs()
                      .hour(selectedAppointment.hour)
                      .minute(selectedAppointment.minute)
                  : dayjs().hour(15).minute(0),
                client: selectedAppointment
                  ? selectedAppointment.client
                  : undefined,
                newDate: selectedAppointment
                  ? dayjs()
                      .year(selectedAppointment.year)
                      .month(selectedAppointment.month)
                      .date(selectedAppointment.date)
                  : undefined,
              }}
            >
              <Row gutter={8}>
                <Col span={24} style={{ paddingBottom: 24 }}>
                  <Typography.Link
                    italic
                    onClick={() => {
                      setAddingAppointment(false);
                      setSelectedAppointment(undefined);
                    }}
                    style={{ fontSize: '150%' }}
                  >
                    <DoubleLeftOutlined style={{ paddingRight: 8 }} />
                    Ver Citas
                  </Typography.Link>
                </Col>
                {selectedAppointment && (
                  <Col span={16}>
                    <Form.Item name="newDate" label="Fecha">
                      <DatePicker format={'ddd DD MMM YYYY'} size="large" />
                    </Form.Item>
                  </Col>
                )}
                <Col span={8}>
                  <Form.Item name="time" label="Hora">
                    <TimePicker
                      format={'HH:mm'}
                      minuteStep={15}
                      placeholder="15:00"
                      size="large"
                      showNow={false}
                      onSelect={(v) => {
                        console.log(v);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item name="client" label="Nombre">
                    <Input
                      placeholder="elias.ainsworth"
                      suffix={<UserOutlined />}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Button
                    htmlType="submit"
                    block
                    size="large"
                    icon={<SaveOutlined />}
                  >
                    Guardar Cita
                  </Button>
                </Col>
                {selectedAppointment && (
                  <Col span={24} style={{ marginTop: 8 }}>
                    <Button
                      block
                      size="large"
                      icon={<DeleteOutlined />}
                      loading={removeAppointment.loading}
                      onClick={async () => {
                        try {
                          await removeAppointment.remove(
                            selectedAppointment.id!,
                          );
                          await load();
                          setSelectedAppointment(undefined);
                        } catch (error) {
                          notification.error({
                            message: 'Error',
                          });
                        }
                      }}
                    >
                      Eliminar Cita
                    </Button>
                  </Col>
                )}
              </Row>
            </Form>
          ) : (
            <Row gutter={8} style={{ paddingTop: 16 }}>
              <Col span={24}>
                <Button
                  onClick={() => setAddingAppointment(true)}
                  block
                  size="large"
                  icon={<PlusSquareOutlined />}
                >
                  Agregar Cita
                </Button>
              </Col>
            </Row>
          )}
        </Spin>
      </Modal>
      <Calendar
        onChange={onChange}
        style={{ padding: 8, borderRadius: 5 }}
        onSelect={onSelect}
        dateFullCellRender={(value) => {
          const appointmentMaxCount = 2;
          const appointmentsForDay = appointments.filter((appointment) => {
            const sameDay = value.date() === appointment.date;
            const sameMonth = value.month() === appointment.month;
            const sameYear = value.year() === appointment.year;
            return sameDay && sameMonth && sameYear;
          });
          const today = dayjs();
          const isCurrentMonth =
            currentMonth === value.month() != undefined && currentYear === value.year();
          const isCurrentDate =
            today.isSame(value, 'date') &&
            today.isSame(value, 'month') &&
            today.isSame(value, 'year');

          const tooMuch = appointmentsForDay.length > appointmentMaxCount;
          const free = isCurrentMonth && appointmentsForDay.length === 0;
          return (
            <Spin spinning={loading}>
              <Row
                className={`
                                ${styles.dayCell}  
                                ${isCurrentMonth && styles.currentMonth}
                                ${free && mode === 'hide' && styles.free}
                                `}
              >
                <Col
                  className="ant-picket-calendar-date-value"
                  style={{
                    position: 'absolute',
                    height: 0,
                    right: 8,
                    bottom: 35,
                  }}
                >
                  <Typography.Text
                    style={{
                      fontSize: 24,
                      fontWeight: 800,
                      color: isCurrentDate ? '#ff85c0' : '#555',
                      opacity: 0.3,
                    }}
                    italic
                  >
                    {value.date()}
                  </Typography.Text>
                </Col>
                <Col
                  className="ant-picker-calendar-date-content"
                  style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    padding: 4,
                  }}
                >
                  {mode === 'show' && (
                    <ul
                      style={{ listStyle: 'none', margin: 0, paddingLeft: 4 }}
                    >
                      {appointmentsForDay
                        .sort((a, b) => (a.unixtime > b.unixtime ? 1 : -1))
                        .slice(0, 2)
                        .map((appointment) => {
                          return (
                            <li
                              key={`${appointment.hour}${appointment.minute}`}
                            >
                              <Typography.Text
                                ellipsis
                                style={isCurrentMonth ? {} : { opacity: 0.2 }}
                              >
                                <Badge
                                  color={isCurrentMonth ? '#ff85c0' : '#555'}
                                />
                                <span
                                  style={{
                                    fontFamily: 'Noto Sans Mono',
                                    fontWeight: 900,
                                    fontSize: '75%',
                                  }}
                                >
                                  {dayjs()
                                    .hour(appointment.hour)
                                    .minute(appointment.minute)
                                    .format('HH:mm')}
                                </span>{' '}
                                {appointment.client}
                              </Typography.Text>
                            </li>
                          );
                        })}
                      {tooMuch && (
                        <li>
                          <Typography.Text
                            ellipsis
                            style={isCurrentMonth ? {} : { opacity: 0.2 }}
                          >
                            <Badge
                              color={isCurrentMonth ? '#ff85c0' : '#555'}
                            />
                            <span
                              style={{
                                fontFamily: 'Noto Sans Mono',
                                fontWeight: 900,
                                fontSize: '75%',
                              }}
                            >
                              +{appointmentsForDay.length - appointmentMaxCount}
                            </span>
                          </Typography.Text>
                        </li>
                      )}
                    </ul>
                  )}
                  {mode === 'hide' && free && (
                    <Row>
                      <HeartOutlined
                        style={{
                          position: 'absolute',
                          top: 16,
                          left: 16,
                          fontSize: 16,
                          color: '#ff85c0',
                        }}
                      />
                    </Row>
                  )}
                </Col>
              </Row>
            </Spin>
          );
        }}
        defaultValue={currentDate}
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const start = 0;
          const end = 12;

          //console.log('value', value.format("YYYY MM"))

          const months: { index: number; value: string }[] = [];
          for (let i = 0; i < 12; i++) {
            months.push({
              index: i,
              value: dayjs().month(i).format('MMMM'),
            });
          }

          const monthOptions = months.map((monthOption) => {
            const { index, value } = monthOption;
            return (
              <Select.Option className="month-item" key={index} value={index}>
                {value}
              </Select.Option>
            );
          });
          const month = value.month();

          const year = dayjs().year();
          const years: { value: number }[] = [];
          for (let i = year - 10; i < year + 10; i += 1) {
            years.push({
              value: i,
            });
          }

          const yearsOptions = years.map((year) => {
            const { value } = year;
            return (
              <Select.Option key={value} value={value} className="year-item">
                {value}
              </Select.Option>
            );
          });

          return (
            <Content>
              <Row align="middle" justify="space-around">
                <Col>
                  <Row>
                    {/* <Typography.Link
                                            style={{ opacity: 0.5, paddingRight: 16, fontSize: 28 }}
                                            italic
                                            strong
                                        >
                                            {' '}
                                            @beeroori.tattoo
                                        </Typography.Link> */}
                    <Col>
                      <Button
                        size="large"
                        type="link"
                        icon={<CaretLeftFilled />}
                        onClick={() => {
                          onChange(dayjs(value).subtract(1, 'month'));
                        }}
                      ></Button>
                    </Col>
                    <Col>
                      <Select
                        size="large"
                        style={{
                          fontSize: 25,
                          fontStyle: 'italic',
                          opacity: 0.3,
                          //paddingRight: 16,
                        }}
                        dropdownMatchSelectWidth={false}
                        onChange={(selectedMonth) => {
                          onChange(dayjs(value).month(selectedMonth));
                          //setCurrentDate(dayjs(value).month(selectedMonth));
                        }}
                        bordered={false}
                        value={value.month()}
                      >
                        {monthOptions}
                      </Select>
                    </Col>
                    <Col>
                      <Select
                        size="large"
                        style={{
                          fontSize: 28,
                          fontStyle: 'italic',
                          opacity: 0.3,
                          margin: 0,
                          //paddingRight: 16,
                        }}
                        //suffixIcon={false}
                        //dropdownMatchSelectWidth={false}
                        className="my-year-select"
                        onChange={(newYear) => {
                          onChange(dayjs(value).year(newYear));
                          //setCurrentDate(dayjs(value).year(newYear));
                        }}
                        bordered={false}
                        value={value.year()}
                      >
                        {yearsOptions}
                      </Select>
                    </Col>
                    <Col>
                      <Button
                        size="large"
                        type="link"
                        icon={<CaretRightFilled />}
                        onClick={() => {
                          onChange(dayjs(value).add(1, 'month'));
                        }}
                      ></Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Content>
          );
        }}
      />

      <Row
        align="middle"
        gutter={16}
        justify="space-between"
        style={{ padding: 8 }}
      >
        <Col>
          <Switch
            checkedChildren={<EyeOutlined />}
            unCheckedChildren={<EyeInvisibleOutlined />}
            checked={mode === 'show'}
            onChange={() => {
              if (mode === 'show') setMode('hide');
              if (mode === 'hide') setMode('show');
            }}
          />
        </Col>
        {/* <Col>
          <Typography.Link
            style={{ opacity: 0.5, paddingRight: 16, fontSize: 28 }}
            italic
            onClick={() => onChange(dayjs())}
          >
            Ver hoy
          </Typography.Link>
        </Col> */}
        {/* <Button size="small" type="ghost" >Ver Hoy</Button> */}
      </Row>
    </Content>
  );
}
