import React from 'react';
import useAuthenticator from './model'
import Loader from '@/templates/Loader'

const Authenticator : React.FC = (props) => {
    const { children } = props;
    const { ready, progress } = useAuthenticator();
    
    //React.useEffect()
    console.log('props change')
    return (<React.Fragment>{ready ? children : <Loader progress={progress}/>}</React.Fragment>)
}

export default Authenticator;
