import HTTP from '@/common/http';
import { Appointment } from '@/common/structures';

const { request } = HTTP;

export namespace Appointments {
  export const createOne = async (
    appointment: Appointment,
  ): Promise<{ status: boolean }> => {
    return await request().post('create_appointment', { data: appointment });
  };

  export const modifyOne = async (
    appointmentId: number,
    appointment: Appointment,
  ): Promise<{ status: boolean }> => {
    return await request().put(`modify_appointment/${appointmentId}`, {
      data: appointment,
    });
  };

  export const removeOne = async (
    appointmentId: number,
  ): Promise<{ status: boolean }> => {
    return await request().delete(`remove_appointment/${appointmentId}`);
  };

  export const fetchFromUser = (user: string) => {
    return {
      insideYearMonth: async (
        year: number,
        month: number,
      ): Promise<Appointment[]> => {
        return request().get(`appointments/from/${user}/at/${year}/${month}`);
      },

      between: async (
        opening: number,
        closing: number,
      ): Promise<{
        appointments: Appointment[];
      }> => {
        return request().get(
          `appointments/from/${user}/between/${opening}/${closing}`,
        );
      },
    };
  };
}
