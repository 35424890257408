import React from 'react'
import { Layout, Row, Col, Progress } from 'antd'

const { Content } = Layout
const Loader : React.FC<{progress:number}> = ({progress}) => {

    return (
        <Content>
            <Row justify='space-around' align='middle' style={{height: '100vh', }}>
                <Col span={20}>
                    <Progress percent={progress} showInfo={false} strokeColor={"#ff85c0"} />
                </Col>
            </Row>
        </Content>
    )
}

export default Loader