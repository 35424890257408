import { useState, useEffect } from 'react';
import { createModel } from 'hox';
import { Appointment } from '@/common/structures';
import useFetchAppointmentFromUserAtYearAndMonth from '@/models/useFetchAppointmentFromUserAtYearAndMonth';

import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import 'dayjs/locale/es';

dayjs.extend(localeData);
dayjs.locale('es');

export default createModel(() => {
  const now = dayjs();
  const [currentYear, setCurrentYear] = useState(now.year());
  const [currentMonth, setCurrentMonth] = useState(now.month());
  const [currentDate, setCurrentDate] = useState<Dayjs>(now);
  const [currentUser, setCurrentUser] = useState<string>('');

  const [selectedDate, setSelectedDate] = useState<Dayjs>();
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment>();
  const [appointmentsForSelectedDate, setAppointmentsForSelectedDate] =
    useState<Appointment[]>([]);
  const [mode, setMode] = useState<'show' | 'hide'>('show');
  const [loading, setLoading] = useState<boolean>(true);
  const [addingAppointment, setAddingAppointment] = useState<boolean>(false);
  const fetchAppointments = useFetchAppointmentFromUserAtYearAndMonth();

  const load = async () => {
    setLoading(true);
    try {
      if (currentUser && currentMonth != undefined && currentYear) {
        const appointments = await fetchAppointments.fetch(
          currentUser,
          currentMonth,
          currentYear,
        );
        setAppointments(appointments);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(currentUser, currentYear, currentMonth);
    load();
  }, [currentUser, currentYear, currentMonth]);

  useEffect(() => {
    if (selectedDate && appointments.length) {
      const appointmentsForSelectedDate = appointments.filter((appointment) => {
        const sameDay = selectedDate?.date() === appointment.date;
        const sameMonth = selectedDate?.month() === appointment.month;
        const sameYear = selectedDate?.year() === appointment.year;
        return sameDay && sameMonth && sameYear;
      });
      setAppointmentsForSelectedDate(appointmentsForSelectedDate);
    } else {
      setAppointmentsForSelectedDate([]);
    }
  }, [selectedDate, appointments]);

  useEffect(() => {
    console.log(currentDate)
    setCurrentMonth(currentDate.month());
    setCurrentYear(currentDate.year());
  }, [currentDate]);

  const selectDate = (date: Dayjs) => {
    if (date.month() == currentMonth) {
      setSelectedDate(date);
    }
  };

  return {
    load,
    loading,
    currentYear,
    setCurrentYear,
    currentMonth,
    setCurrentMonth,
    currentDate,
    setCurrentDate,
    selectedDate,
    setSelectedDate,
    selectDate,
    currentUser,
    setCurrentUser,
    selectedAppointment,
    setSelectedAppointment,
    appointments,
    appointmentsForSelectedDate,
    setAppointmentsForSelectedDate,
    mode,
    setMode,
    addingAppointment,
    setAddingAppointment,
  };
});
