import { extend } from 'umi-request';
import { Modal } from 'antd';
//import { sleep } from '@/common/utilities';
const connectionError = new Error(
	'No se ha podido alcanzar una conexión con el origen de datos',
);
const environment = process.env.NODE_ENV;
console.log(environment);
const prefix =
	environment === 'production' ? '/' 
    // : 'https://oyasumi.cl/';
    : 'http://localhost:8080/'



const errorHandler = async (error :any) => {
	if (error.response == null) {
		//reset();
		throw connectionError;
	} else {
		if (error.response.status === 401) {
			Modal.error({
				title: 'Error',
				content: 'Su sesión ha expirado..',
			});
			//await sleep(600);
			//window.location.reload();
			return;
		}

		if (error.response.status === 403) {
			Modal.error({
				title: 'Error',
				content: 'No tiene los privilegios para realizar esta acción.',
			});
			//await sleep(300);

			//window.location.reload();
			return;
		}

		throw new Error(error.data.message);
	}
};

namespace HTTP {
	export const request = () =>
		extend({
			errorHandler: errorHandler,
			prefix: prefix,
			requestType: 'json',
			responseType: 'json',
		});
}

export default HTTP;