import { useState } from 'react';
import { createModel } from 'hox';
import { Appointment } from '@/common/structures';
import { Appointments } from '@/services/appointments';

export default createModel(() => {
  const [loading, setLoading] = useState<boolean>(false);
  const remove = async (appointmentId: number): Promise<void> => {
    try {
      setLoading(true);
      await Appointments.removeOne(appointmentId);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return {
    remove,
    loading,
  };
});
