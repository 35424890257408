import { useState, useEffect } from "react";
import { createModel } from "hox";
import delay from "@/common/delay";

export default createModel(() => {

  const [ready, setReady] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0); 

  useEffect(() => {
    (async () => {
        setProgress(0)
        await delay(200, () => {
            setProgress(20)
        })
        await delay(300, () => {
            setProgress(40)
        })
        await delay(150, () => {
            setProgress(60)
        })
        await delay(50, () => {
            setProgress(80)
        })
        await delay(50, () => {
            setProgress(100)
            
        })
        await delay(500, () => {
            setReady(true)
        })
        
    })();
  }, [])

  return {
      ready,
      progress
  }
});